.root {
  background: black;
  margin-top: 100px;
  padding-top: 100px;
  padding-bottom: 100px;
}

.header {
  color: #f7f8f6;
}

.buttons {
  margin-top: 50px;
}

.requestButton {
  margin-right: 20px;
}