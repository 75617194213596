.filterSelector {
  margin: 0 8px 8px 0;
  font-size: 0.70rem;
}

.dateField {
  margin-right: 16px;
  margin-bottom: 8px;
}

.filterButtons {
  display: flex;
  flex-wrap: wrap;
}