.container {
  width: 320px;
  margin-right: 1em;
}

.row {
  border-bottom: 1px solid #eeeeee;
  display: flex;
  justify-content: space-between;
}
