.singleCard {
    min-height: 265px;
    position: relative;
}

@media (max-width: 960px) {
    .singleCard {
        min-height: auto;
    }
}

.singleCardLast {
    min-height: 390px;
}

@media (max-width: 960px) {
    .singleCardLast {
        min-height: auto;
    }
}

.neutralLink {
    text-decoration: none;
    color: black;
    cursor: pointer;
}