.root {
    border: none;
    border-radius: 0;
    height: 182px;
}
.header {
    margin-top: 48px;
    min-height: 38px;
    box-sizing: inherit;
}

.subHeader {
    padding-bottom: 25px;
}
.tabsRoot {
    display: flex;
    overflow: hidden;
    box-shadow: inset 0 -5px 0 0 rgba(0,0,0,0.08), inset 0 2px 0 0 rgba(0,0,0,0.08);
    min-height: 48px;
    & > div > div > a {
        max-width: 264px;
        min-width: 160px;
    }
}