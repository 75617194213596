.chartContainer {
  width: '40%';
}

.topPadding {
  margin-top: '35px';
}

.interestPaymentChartHeader {
  margin-bottom: 15px;
}

.principalAmountChartHeader {
  margin-bottom: 15px;
  margin-top: 25px;
}

.cashFlowGrid {
  width: 100%;
  vertical-align: top;
}
