.row {
    & last-child td {
      border-bottom: 0;
    }
  }

.calculator {
    & svg {
      vertical-align: 'middle';
    }
  }

.key {
    margin-right: '10px';
  }
  
.typography {
  display: 'flex';
  align-items: 'center';
  justify-content: 'flex-end';
}
