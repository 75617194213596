.row:last-child td {
  border-bottom: 0;
}

.pledgorCell {
  width: 30%;
}

.customCell {
  width: 30%;
}

.agreementReceivedDateCell {
  width: 20%;
}