.messagebox {
  max-width: 500px;
  margin: 0px auto;
  top: 50%;
  width: 500px;
  padding: 16px;
  margin-top: 200px;
}

.margin {
  margin: 40px;
}

.success {
  border-radius: 20px;
  background-color: #4caf50;
  font-size: 36px;
  padding: 6px;
  color: #fff;
}

.topMargin {
  margin-top: 25px;
}
