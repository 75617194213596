.uploadSection {
    & > * {
        cursor: pointer;
        & * {
            pointer-events: none;
        }
    }
}

.fileFieldText {
    white-space: nowrap;
    color: rgba(0, 0, 0, 0.54);
    & > div > textarea {
        padding: 2rem 1rem .2rem;
        font-size: 1rem;
    }
}