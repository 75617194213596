.text {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.loanName {
    flex-grow: 0;
    flex-basis: 32%;
    text-align: right;
}