.root {
  margin-top: 150px;
}

@media (max-width: 960px) {
  .root {
    margin-top: 80px;
  }
}

.ingress {
  margin-top: 30px;
}

.buttons {
  margin-top: 50px;
}

.requestButton {
  margin-right: 20px;
}

.image {
  box-shadow: 0px 5px 5px #00000033;
  border-radius: 7px;
}