.margin {
  margin: 24px;
}
.topMargin {
  margin-top: 25px;
}
.eulaTextLink {
  font-weight: 500;
}
.icon {
  display: block;
  margin: auto;
}
.topPadding {
  padding-top: 16px;
}
.checkboxGrid {
  text-align: center;
  padding: 0 !important;
}
.hide {
  display: none;
}
.capitalize {
  text-transform: capitalize;
}
