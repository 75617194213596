/* 
Flexbox is needed to place the footer always at the bottom of the page
whatever the height of the page is (but not in a fixed position)
*/
html {
  height: 100%;
}

body, #root:not(.paperPage) {
  height: 100%;
  display: flex;
  flex-direction: column;
}

body::-webkit-scrollbar-thumb {
  background-color: rgb(160, 160, 160);
}

*::-webkit-scrollbar-track {
  background-color: #f7f8f6;
}

*::-webkit-scrollbar {
  width: 10px;
  /* overrides material-table */
  height: 10px !important;
  background-color: #f7f8f6;
}

*::-webkit-scrollbar-thumb {
  /* overrides material-table */
  border-radius: 10px !important;
  background-color: rgb(192, 192, 192) !important;
  border: 0 !important;
}
