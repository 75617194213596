.root {
  margin-top: 200px;
}

@media (max-width: 960px) {
  .root {
    margin-top: 100px;
  }
}

.image {
  box-shadow: 0px 5px 5px #00000033;
  border-radius: 7px;
}

.backgroundIcon {
  font-size: 500px;
  position: absolute;
  top: -100px;
  right: -120px;
  z-index: -1;
  color: #e87f381f;
}

.header {
  margin-bottom: 30px;
}

.icon {
  font-size: 60px;
  margin-right: 20px;
}

.text {
  margin-left: 0;
  margin-right: 0;
}

@media (max-width: 960px) {
  .text {
    margin-left: 100px;
    margin-right: 100px;
  }
}

@media (max-width: 600px) {
  .text {
    margin-left: 0;
    margin-right: 0;
  }
}