.title {
    margin: 32px 0;
}

@media (max-width: 600px) {
    .title {
       padding-left: 20px;
    }
}

.logoAlign {
    align-items: center;
    display: flex;
    justify-content: space-between;
}
.card {
    height: 309px;
    position: relative;
}
