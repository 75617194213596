.reviewTableCell {
  --theme-palette-reviewed: #4caf50;
  --theme-palette-submitted: #e87f38;
  width: 150px;
}

.checkCircle {
  color: var(--theme-palette-reviewed);
  vertical-align: middle;
  margin-right: 8px;
}

.accessTime {
  color: var(--theme-palette-submitted);
  vertical-align: middle;
  margin-right: 8px;
}