.row {
    & last-child td {
      border-bottom: 0;
    }
  }

.typography {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
