.columnContent {
    padding-top: 0;
}

.eventType {
    margin-left: 8px;
}

.eventActionsWrapper {
    display: inline-flex;
    text-align: right;
    white-space: nowrap;
}

@media (max-width: 960px) {
    .eventActionsWrapper {
        white-space: normal;
    }
}

.dateBlock {
    width: 100px;
    padding: 16px;
    margin-right: 16px;
}

.listItem:hover {
    background-color: rgba(0, 0, 0, .04);
}
