.root {
  background: #13161c;
}

.grow {
  flex-grow: 1;
}

.logoLink {
  display: flex;

  & svg {
    width: 300px;

    @media (max-width: 600px) {
      width: 200px;
      margin-left: -20px;
    }
  }
}

.toolbar {
  max-height: 64px;
  padding-left: 0;
  padding-right: 0;
}

.button {
  margin-top: -3px;
}

.pageHeaderLeftSide {
  align-content: flex-start;
  flex-grow: 1;
}