.buttonRow {
  & td {
    border-bottom: 0;
  }
}
.label {
  width: 25%;
}
.fields {
  width: 75%;
}
.inlineField {
  float: left;
  width: 50%;
}
.subCard {
  border: 0;
  box-shadow: none;
}
.cardHeader {
  padding-bottom: 0;
  & svg {
    margin-top: 3px;
  }
  & a {
    color: #13161c;
    &:hover {
      text-decoration: none;
    }
  }
}
