.loanName {
  flex-grow: 0;
  flex-basis: 32%;
  text-align: right;
}

.upcoming {
  color: #e87f38;
  margin-right: 8px;
  float: left;
}

@media (max-width: 600px) {
  .eventIcon {
    font-size: 1rem;
  }
}

.text {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

@media (max-width: 600px) {
  .listItemContainer {
    min-width: 0;
  }
}