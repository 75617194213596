.root {
  padding: 16px ;
}
.profileImageBox {
  margin-top: 16px;
  min-height: 200px;
  background-color: gray;
}
.resetPasswordLink {
  padding-top: 16px;
  display: block;
}
.editIcon {
  font-size: 20pt;
  padding-right: 8px;
}
.parentList {
  padding-top: 8px;
}
.listItem {
  padding: 0;
  display: block;
}
.groupListsHeader {
  padding-top: 16px;
}
.subGroupHeader {
  font-weight: 500;
}
.userCell {
  padding-left: 0;
  color: #0000008a;
  width: 40%;
  white-space: nowrap;
}
.table {
  table-layout: fixed;
}
.tableText {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
