.interpolated {
  margin-left: 24px;
}

.wordWrapBreak {
  word-wrap: break-word;
}

.rightAlign {
  text-align: right;
}

.typography {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}