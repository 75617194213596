.container {
  margin-left: 1em;
  margin-right: 2em;
}

.row {
    border-bottom: 1px solid #eeeeee;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }