.root {
    padding: 16px;
}
.profileImageBox {
    margin-top: 16px;
    min-height: 200px;
    background-color: rgba(0,0,0,0.08);
}
.profileAvatar {
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    color: white;
    font-size: 150pt;
}
.resetPasswordLink {
    padding-top: 16px;
    display: block;
}
.editIcon {
    font-size: 24pt;
    padding-right: 8px;
}
.parentList {
    padding-top: 8px;
}
.listItem {
    padding: 0;
    padding-left: 8px;
    display: block;
}
.groupListsHeader {
    padding-top: 16px;
}
.subGroupHeader {
    font-weight: 500;
}
.changePasswordContainer {
    padding-top: 16px;
}
