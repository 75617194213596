.list {
  margin-bottom: 32px;
}

.item {
  padding: 0;
}

.listIcon {
  min-width: 16px;
}