.keyCell {
  border-bottom: 0;
  padding-left: 0;
  color: #0000008a; /* Replace with actual color value */
}

.valueCell {
  border-bottom: 0;
  padding-left: 0;
  text-align: right;
  white-space: nowrap;
}

.valueCellAlert {
  color: #FF0000; /* Replace with actual color value */
}