.upcoming {
    color: #e87f38;
    margin-right: 8px;
    float: left;
}

.list {
    && :global .Mui-selected {
        background-color: #ced7f4;
    }
}

.secondaryText {
    padding-right: 30px;
}
