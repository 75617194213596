.stickyTable {
  position: relative;
  width: 100%;
  margin: auto;
  overflow: auto;
}

@media (max-width: 960px) {
  .stickyTable tr td:first-child,
  .stickyTable tr th:first-child {
    position: sticky;
    left: 0;
    z-index: 2;
    background-color: var(--mui-palette-header-main, #13161c);
  }

  .stickyTable tr td:first-child h3,
  .stickyTable tr td:first-child h4,
  .stickyTable tr td:first-child span,
  .stickyTable tr td:first-child p,
  .stickyTable tr th:first-child h3,
  .stickyTable tr th:first-child h4,
  .stickyTable tr th:first-child span,
  .stickyTable tr th:first-child p {
    color: var(--mui-palette-primary-contrastText, #fff);
  }
}