.checkbox {
  padding: 0;
}

.removeButtonCell {
  width: 50px;
}

.tableBody {
  white-space: normal;
}