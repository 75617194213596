.icon {
    vertical-align: middle;
    margin-right: 4px;
}

.iconPdf {
    color: #C11E07;
}

.iconWord {
    color: #2B579A;
}

.iconExcel {
    color: #227447;
}