
.listItem {
  padding-top: 0;
  padding-bottom: 0;
  padding-left: 0;
}

.editIcon {
  font-size: 20pt;
  padding-right: 5px;
}