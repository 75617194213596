
.root {
    padding: 16px;
}

.card {
    min-height: 100%;
}

.hotJarLink {
    padding-left: 8px;
    padding-top: 8px;
}
