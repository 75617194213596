
.test {
    background-color: rgb(77, 163, 9);
}

.uat {
    background-color: rgb(31, 114, 211);
}

.hotfix {
    background-color: rgb(235, 133, 14);
}
.chip {
    text-transform: uppercase;
    color: white;
    margin: 0 0.5rem;
    font-weight: 500;
}