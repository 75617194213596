.interpolated {
    margin-left: 24px;
}

.keyCell {
    padding-left: 0;
    color: #0000008a;
    overflow: hidden;
    white-space: normal;
}

.valueCell {
    padding-left: 0;
    white-space: normal;
}

.tableText {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}