.loader {
  text-align: center;
  margin: 40px 0;
}

.rectIcon {
  height: 40px;
  width: 40px;
  margin-right: 60px;
  margin-left: 16px;
  margin-top: 16px;
}

.listFullLine {
  margin-bottom: 3px;
  margin-right: 16px;
  margin-top: 16px;
}

.fullLine {
  margin-bottom: 3px;
}

.shorterLine {
  width: 80%;
  margin-right: 16px;
}

.headerLine {
  width: 30%;
  margin-bottom: 5px;
}

.chartIcon {
  width: 150px;
  height: 150px;
}

.squareChart {
  min-height: 295px;
}

.page {
  margin-top: 1rem;
  align-self: center;
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 1rem;
}

.pageHeading {
  font-size: 24px;
  font-weight: bold;
}

.pageSection {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  width: 100%;
  justify-content: space-between;
  & > span {
    display: inherit;
    flex-grow: 1;
    max-width: unset;
  }
}
.pageSectionColumn {
  min-height: 60vh;
}