.root {
  margin-top: 200px;
}

@media (max-width: 960px) {
  .root {
    margin-top: 100px;
  }
}

.card {
  height: 100%;
  width: 350px;
}

.icon {
  font-size: 60px;
  margin-right: 20px;
}

.header {
  margin-bottom: 50px;
}

@media (max-width: 960px) {
  .header {
    margin-left: 100px;
    margin-right: 100px;
  }
}

@media (max-width: 600px) {
  .header {
    margin-left: 0;
    margin-right: 0;
  }
}