
@media (min-width: 700px) {
  .dialogContent {
    width: 500px;
  }
}

.icon {
  vertical-align: middle;
  margin-right: 8px;
}

.buttonIcon {
  color: var(--palette-primary-main);
  position: absolute;
}

.formControlLabel {
  margin-top: 16px;
}

.warningDiv {
  margin-top: 16px;
}

.documentRow {
  border-bottom: 1px solid grey;
  margin-top: 16px;
}

.checkbox {
  align-self: baseline;
}
