.contactDetails {
  list-style: none;
  padding: 0;
}

.contactItem {
  font-size: 17px;
}
.contactItem:not(:last-child) {
  margin-bottom: 1rem;
}

.icon {
  vertical-align: middle;
  margin-right: 8px;
}
