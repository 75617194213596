.card {
    margin-bottom: 16px;
}
.columnContent {
    padding-top: 0;
}
.error {
    color: #FF0000;
    margin-right: 8px;
    float: left;
}
.upcoming {
    color: #e87f38;
    margin-right: 8px;
    float: left;
}
