.reviewTableCell {
    width: auto;
}
.tableCellDate {
    width: 120px;
}
.tableCellIcon {
    width: 100px;
}

.icon {
    vertical-align: middle;
    margin-right: 4px;
}

.iconPdf {
    color: #C11E07;
}

.iconWord {
    color: #2B579A;
}

.iconExcel {
    color: #227447;
}

.linkButton {
    padding: 0;
    width: 100%;
    text-decoration: none;
    color: #3f18aa;
    font-weight: 400;
    justify-content: left;
    text-transform: initial;
}

.linkButton:hover {
    text-decoration: underline;
    background-color: inherit;
}

.fileTypeButtonText {
    color: #3f18aa;
    text-transform: uppercase;
}

.buttonText {
    color: #3f18aa;
    width: 100%;
    text-overflow: ellipsis;
    text-align: left;
    overflow: hidden;
    display: block;
    white-space: nowrap;
}

.row:last-child td {
    border-bottom: 0;

}

.tableCell {
    & > p {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
}