.card {
  margin-bottom: 16px;
}

.columnContent {
  padding-top: 0;
}

.filterLabel {
  font-weight: 500;
}