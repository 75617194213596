.chip {
  margin-left: 8px;
  margin-right: 0;
  min-width: 65px;
  background-color: #fff;
}
.overdue {
  color: #ff0000;
  border-color: #ff0000;
}
.submitted {
  color: #dead00;
  border-color: #dead00;
}
.received {
  color: #4caf50;
  border-color: #4caf50;
}
.future {
  color: #979797;
  border-color: #979797;
}
