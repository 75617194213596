.rootSide {
  display: flex;
  align-items: center;
}

.progressBarSideContrainer {
  flex: 1;
  position: relative;
  margin: 24px 32px;
}

.progressBar {
  height: 20px;
  border-radius: 10px;
}

.startDateBelow {
  float: left;
}

.endDateBelow {
  float: right;
}

.button {
  border: 8px solid #3f18aa;
  padding: 4px;
  background: #3f18aa;
  color: #FFF;
}

.button:hover {
  background-color: #2c1076;
  border: 8px solid #2c1076;
}

.buttonComplete {
  border: 8px solid #b6a7de;
  padding: 4px;
  background: #FAFAFA;
  color: #b6a7de;
}

.buttonComplete:hover {
  background-color: #b6a7de;
  color: #FFF;
}

.diagonalLine {
  background: repeating-linear-gradient(135deg,#6546bb, #6546bb 3px, #b6a7de 3px,#b6a7de 8px);
}