.card {
    margin-bottom: 16px;
}

.dateField {
    margin-bottom: 32px;
    margin-top: 16px;
}

.filterLabel {
    font-weight: 500;
}

.columnContent {
    padding-top: 0;
}
