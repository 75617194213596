.typography {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.row {
  & :global .MuiTableCell-root:last-child {
    padding-right: 16px;
  }
}
