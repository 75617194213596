.root {
  margin-top: 100px;
}

.header {
  margin-bottom: 30px;
}

.text {
  margin-left: 0;
  margin-right: 0;
}

@media (max-width: 960px) {
  .text {
    margin-left: 100px;
    margin-right: 100px;
  }
}

@media (max-width: 600px) {
  .text {
    margin-left: 0;
    margin-right: 0;
  }
}