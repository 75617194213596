
.content {
  display: flex;
  align-items: center;
}

.textWrapper {
  margin-left: 24px;
}

.photo {
  max-width: 150px;
  max-height: 150px;
  margin-bottom: 8px;
  border-radius: 15%;
}

.avatarIcon {
  width: 100px;
  height: 150px;
  color: white;
  background-color: #00000014;
}
