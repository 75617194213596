
.filterContainer {
    display: flex;
    align-items: center;
    marginBottom: 1rem;
}
.filterContainerOn {
    background: rgba(0, 0, 0, 0.04);
}

.filterBox {
    padding: 0 1rem;
    width: 100%;
    display: flex;
    alignItems: center;
    gap: 1rem;
}

.issueNameInput {
    marginRight: 1rem;
    marginLeft: 1rem;
}

.filterItem {
    marginRight: 1rem;
}


